<template>
  
    <banner :banner="banner" :caption="caption" :path="bannerPath"></banner>

    <section class="content-home border-bottom-red">
        <div class="container-fluid">
            <div class="row">
                <div class="col-2">
                    <div class="content-block">
                        <img class="icon-red img-left" src="../../assets/images/sekilas-tentang-w.svg" alt="icon">
                    </div>
                </div>

                <div class="col-10">
                    <div class="content-block">
                        <h3 class="mb-3">Career of <span class="alternate">Bahtera Adhiguna</span></h3>

                        <h6 class="text-red">
                            {{judul}}
                        </h6>
                        <p v-html="karir"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

import Banner from '../banner/banner.vue'
import axios from 'axios'

export default {
    name: 'readCareer',
    components: {
        Banner,
    },
    props: [
        'kode'
    ],
    data () {
        return {
            // bannerPath: 'http://localhost:8080/assets/banner/',
            bannerPath: 'https://api.plnbag.co.id/assets/banner/',
            banner: '',
            caption: '',
            judul: '',
            karir: ''
        }
    },
    mounted () {
        this.loadBanner()
        this.loadData()
    },
    methods: {
        loadBanner () {
            axios.get('karir_banner')
                .then((res) => {
                    this.banner = res.data.data.banner
                    this.caption = res.data.data.caption_en
                })
        },

        loadData () {
            axios.get('karir_details?kode=' + this.kode)
                .then((res) => {
                    this.judul = res.data.data.judul
                    this.karir = res.data.data.karir
                })
        }
    }
}
</script>

<style scoped>
    @import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Montserrat:300,400,500,700");

    h1, h2, h3, h4, h5, h6 {
        font-family: "Montserrat", sans-serif;
        color: #222222;
        font-weight: 600;
    }

    h1 {
        font-size: 2.8rem;
    }

    h2 {
        font-size: 2.5rem;
    }

    h3 {
        font-size: 2.25rem;
    }

    h4 {
        font-size: 1.6875rem;
    }

    h5 {
        font-size: 1.375rem;
    }

    h6 {
        font-size: 1.25rem;
    }

    p, a, li, blockquote, label {
        font-size: 1rem;
        line-height: 26px;
        color: #848484;
        margin-bottom: 0;
    }
    
    .content-home {
        position: relative;
    }

    .content-home .content-block {
        padding: 25px 0 0;
    }

    .content-home .content-block h3 {
        color: #0158a7;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block span.title-w {
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block p.text-w {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .content-block p {
        color: #404041;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .image-block {
        position: absolute;
        right: 5%;
        bottom: 0;
    }

    @media (max-width: 767px) {
        .content-home .image-block {
            display: none;
        }
    }

    .border-bottom-red {
        border-bottom: 5px solid #ea2e23;
    }

    .icon-red {
        background-color: #ea2e23;
        border-radius: 5px;
        padding: 10px;
        float: right;
    }

    @media (max-width: 767px) {
        .icon-red {
            float: none;
        }
    }

    .img-left {
        width: 85px;
        height: auto;
    }

    @media screen and (max-width: 767px) {
        .img-left {
            width: 45px;
            height: auto;
        }
    }

    span.alternate {
        color: #ea2e23;
        font-weight: 800;
    }

    .text-red {
        color:  #ea2e23;
    }
</style>